
import React, { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BASE_URL } from "../../constant/url";

const theme = createTheme();

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is invalid";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setLoading(true);

    try {
      const response = await fetch(
        `${BASE_URL}/user/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setErrors({ ...errors, form: "Request failed. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      className="Forget-password-page"
      sx={{
        minHeight: '100vh', // Ensure the Box takes at least the full height of the viewport
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#101846',
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: 'white',
              padding: 4, // Add padding for spacing
              borderRadius: 3, // Optional: Add border radius for rounded corners
              boxShadow: 3, // Optional: Add box shadow for a subtle elevation effect
            }}
          >
            <Box className="logo-section">
              <img
                src="https://zibtek.nyc3.cdn.digitaloceanspaces.com/Mazzzing/insurality.png"
                alt="Logo"
                style={{ height: 40 }}
              />
            </Box>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            {success ? (
              <Typography variant="body1" color="success.main">
                Check your email for reset instructions.
              </Typography>
            ) : (
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
                {errors.form && (
                  <Typography color="error" variant="body2">
                    {errors.form}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Reset Link"}
                </Button>
                <Link href="/" variant="body2">
                  Back to Sign In
                </Link>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default ForgotPasswordPage;
