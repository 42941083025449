import React from "react";
import { Typography, Divider } from "@mui/material";
import './HeadingList.css'

const Header = ({ name,isPriority }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between",marginTop: 40 }}>
        <Typography variant="h6" gutterBottom sx={{ textAlign: "left",margin: 0,fontWeight: 600 }} className="headerSpace">
          {name}
        </Typography>
        {isPriority && (
          <Typography variant="h6" className="priorityAlign" gutterBottom sx={{ textAlign: "left",margin: 0,fontWeight:600}}>
            PRIORITY
          </Typography>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default Header;
