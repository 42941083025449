import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api/tokenCode';

const FinalDataContext = createContext();

export const FinalDataProvider = ({ children }) => {
  const [finalData, setFinalData] = useState(null);
  const clientData = JSON.parse(localStorage.getItem('client'));
  const userId = clientData?.user_id;

  // useEffect(() => {
  //   const fetchFinalData = async () => {
  //     try {
  //       const response = await api.get(`/coverage/getAllFinalExpenseCoverageDetails/${userId}`);
  //       setFinalData(response.data.coverageData);
  //     } catch (error) {
  //       console.error("Error fetching final coverage data:", error);
  //     }
  //   };

  //   if (userId) {
  //     fetchFinalData();
  //   }
  // }, [userId]);

  return (
    <FinalDataContext.Provider value={finalData}>
      {children}
    </FinalDataContext.Provider>
  );
};

export const useFinalData = () => useContext(FinalDataContext);
