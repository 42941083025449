import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, Link } from "react-router-dom";
import "./LoginPage.css";
import api, { setAuthToken } from "../../api/tokenCode";
import { notifySuccess, notifyError } from '../../constant/toast';
import { ToastContainer } from "react-toastify";

const theme = createTheme();

function LoginPage({ onLogin }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is invalid";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setLoading(true);

    try {
      const response = await api.post("/user/login", {
        email: email,
        password: password,
      });

      const loginData = JSON.stringify(response.data, null, 2);
      const data = response.data?.accessToken;

      localStorage.setItem("access_token", data);
      localStorage.setItem("login_data", loginData);

      setAuthToken(data);

      onLogin(email);
      notifySuccess("Login Successful");
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
      notifyError(`${error.response.data.message}`);
      setErrors({ ...errors, form: "Login failed. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="login-page">
      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            className="login-container"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className="logo-section">
              <img
                src="https://zibtek.nyc3.cdn.digitaloceanspaces.com/Mazzzing/insurality.png"
                alt="Logo"
                style={{ height: 40 }}
              />
            </Box>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  width: "100%",
                  padding: "20px",
                  marginBottom: "10px",
                  border: errors.email ? "1px solid red" : "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.email && (
                <Typography color="error" variant="body2">
                  {errors.email}
                </Typography>
              )}

              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "100%",
                  padding: "20px",
                  marginBottom: "10px",
                  border: errors.password ? "1px solid red" : "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.password && (
                <Typography color="error" variant="body2">
                  {errors.password}
                </Typography>
              )}

              {errors.form && (
                <Typography color="error" variant="body2">
                  {errors.form}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? "Signing In..." : "Sign In"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <ToastContainer />
    </Box>
  );
}

export default LoginPage;
