import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import "./RightNav.css";
import api from '../../api/tokenCode';


const RightNav = () => {
  const [open, setOpen] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [clientDatas, setClientData] = useState([]);

  const [checkedPriorities, setCheckedPriorities] = useState([]);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userId = clientData?.user_id || "";


  const toggleNav = () => {
    setOpen(!open);
  };

  const groupPriorities = (priorities) => {
    const categoryMap = {
      assetsIncome: "Family/Background",
      assetsData: "Income",
      futureIncome: "Future Income",
      futurePlanning: "Future Planning"
    };

    return priorities.reduce((groups, path) => {
      // Extract category from the path prefix

      const prefix = path.split(" - ")[0];

      const category = categoryMap[prefix] || "Other";

      // Group paths by their category
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(path);

      return groups;
    }, {});
  };

  const handleChangeCheckbox = async (item) => {
    const val = item.split("-").map((item) => item.trim());
    const [objRes, copySubVal, ind, indVal] = val;

    // Create a deep copy to avoid mutating the original state
    const updatedClientData = JSON.parse(JSON.stringify(clientData));


    // Update the specific checkbox value
    if (Array.isArray(updatedClientData[objRes][copySubVal])) {
      updatedClientData[objRes][copySubVal][ind][indVal] = false;
    } else {
      updatedClientData[objRes][copySubVal][ind] = false;
    }


    try {
      const response = await api.put(`/form/updateForm/${userId}`, {
        assetsData: updatedClientData.assetsData,
        assetsIncome: updatedClientData.assetsIncome,
        clientInfo: updatedClientData.clientInfo,
        futureIncome: updatedClientData.futureIncome,
        futurePlanning: updatedClientData.futurePlanning,
      });


      // Sync with local state and localStorage
      setClientData(updatedClientData);
      localStorage.setItem("client", JSON.stringify(updatedClientData));
    } catch (error) {
      console.error("Error updating client data:", error);
    }
  };


  const handleTogglePriority = (index, item) => {
    const newChecked = [...checkedPriorities];
    newChecked[index] = !newChecked[index];

    if (!newChecked[index]) {
      // Remove from priorities and checked arrays
      const updatedPriorities = priorities.filter((_, i) => i !== index);
      const updatedCheckedPriorities = newChecked.filter((_, i) => i !== index);

      setPriorities(updatedPriorities);
      setCheckedPriorities(updatedCheckedPriorities);

      // Update the server and local storage
      handleChangeCheckbox(item);
    } else {
      setCheckedPriorities(newChecked);
    };
  };

  // Helper function to extract all `true` values with descriptive paths
  const extractTrueValues = (data, prefix = "") => {
    let result = [];
    for (const key in data) {
      const value = data[key];
      if (value === true) {
        result.push(prefix ? `${prefix} - ${key}` : key);
      } else if (typeof value === "object" && value !== null) {
        result = result.concat(extractTrueValues(value, prefix ? `${prefix} - ${key}` : key));
      }
    }
    return result;
  };


  const formatFinalName = (path) => {
    const parts = path.split(" - "); // Split by " - "
    const finalPart = parts[parts.length - 1]; // Get the last part
    return (
      finalPart
        .replace(/([A-Z])/g, " $1") // Insert spaces before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
        .trim()
    );
  };


  useEffect(() => {
    // Assuming `response` is the API response JSON object
    const response = clientData;


    // Extract priorities and initialize checkboxes
    const extractedPriorities = extractTrueValues(response);
    setPriorities(extractedPriorities);
    setCheckedPriorities(Array(extractedPriorities.length).fill(true));
  }, []);


  return (
    <Box
      sx={{
        width: open ? 350 : 60,
        height: "100vh",
        overflowY: "scroll",
        transition: "width 0.3s",
        bgcolor: "background.paper",
        boxShadow: 1,
        position: "relative",
      }}
    >
      <IconButton
        onClick={toggleNav}
        sx={{
          position: "absolute",
          top: "55px",
          left: -14,
          transform: "translateY(-50%)",
          background: "#e4e6eb",
          "&:hover": {
            background: "#E4E6EB",
          },
        }}
      >
        {open ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
      {open && (
        <Box sx={{ padding: 2, mt: 10 }}>
          <Typography variant="h6" className="priorStyle">
            PRIORITIES
          </Typography>
          <Divider sx={{ mx: 3 }} />

          {/* Group items by categories */}
          {Object.entries(groupPriorities(priorities)).map(([category, items]) => (
            <Box key={category} sx={{ mt: 2 }}>
              {/* Display category heading */}
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {category}
              </Typography>
              <List sx={{ px: 0 }}>
                {items.map((path, index) => {
                  // Split path into parts for traversal
                  const pathParts = path.split(" - ");
                  const finalKey = pathParts.pop(); // Extract the last key ("incomeReplacement")
                  let currentData = clientData;

                  // Safely traverse the object structure up to the parent of the final key
                  pathParts.forEach((part) => {
                    if (currentData) {
                      if (!isNaN(part)) {
                        // Handle numeric indices for arrays
                        currentData = Array.isArray(currentData) ? currentData[parseInt(part)] : undefined;
                      } else {
                        // Handle object keys
                        currentData = currentData[part];
                      }
                    }
                  });

                  // Extract additional info (e.g., annual, amount) from the parent object
                  const additionalInfo = currentData?.annual || currentData?.balance || currentData?.currentValue || null;

                  return (
                    <ListItem
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent", // Prevent background change on hover
                        },
                      }}
                      dense
                      button
                    >
                      <input
                        type="checkbox"
                        className="checkboxDesignRight"
                        checked={checkedPriorities[index]}
                        onChange={() => handleTogglePriority(index, path)}
                      />
                      <ListItemText
                        primary={
                          additionalInfo
                            ? `${formatFinalName(path)} - $${additionalInfo}`
                            : formatFinalName(path)
                        }
                        className="textFont"
                        sx={{ ml: 2, mb: 0 }}
                      />
                    </ListItem>
                  );
                })}

              </List>
            </Box>
          ))}
        </Box>

      )}
    </Box>
  );
};


export default RightNav;



