import React, { forwardRef, useImperativeHandle } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import IconExport from "@mui/icons-material/FileDownload";

const ExportButton = forwardRef((props, ref) => {
  const handleExportClick = async () => {
    const element = document.getElementById("export");
    if (element) {
      try {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.setTextColor(36, 82, 123); 
        
        pdf.setFontSize(18);
        
        const heading = "LIFETIME REPORT";
        
        const textWidth = pdf.getStringUnitWidth(heading) * pdf.getFontSize() / pdf.internal.scaleFactor;
        const textX = (pdfWidth - textWidth) / 2; 

        pdf.setFont("Arial, fangsong"); 
        
        pdf.text(heading, textX, 20); 

        const xOffset = 10; 
        const imageYPosition = 40; 

        pdf.addImage(image, "PNG", xOffset, imageYPosition, pdfWidth - xOffset, pdfHeight);

        pdf.save("Insurality.pdf");
      } catch (error) {
        console.error("An error occurred while exporting the PDF:", error);
      }
    } else {
      console.error("Element with id 'export' not found");
    }
  };

  useImperativeHandle(ref, () => ({
    export: handleExportClick,
  }));

  return (
    <button
      className=""
      onClick={handleExportClick}
      style={props.style} 
    >
      <IconExport />
    </button>
  );
});

export default ExportButton;
