import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import "./LeftSidenav.css";
// import { ClientContext } from "../../context/ClientContext";
import api from "../../api/tokenCode";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess } from "../../constant/toast";

const LeftSidenav = ({ data }) => {
  const location = useLocation();
  const [debtData, setDebtData] = useState(data || location.state?.debtData);
  const [termData, setTermData] = useState(data || location.state?.termData);
  const [wholeData, setWholeData] = useState(data || location.state?.wholeData);
  const [finalData, setFinalData] = useState(data || location.state?.finalData);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userDetails = JSON.parse(localStorage.getItem("login_data"));

  const userId = clientData?.user_id || '';

  // const termValues = termData[0].clientId;
  // console.log(termValues,"yshgcf");
  // const { client } = useContext(ClientContext);
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  const [isShownDebate, setIsShownDebate] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // State to track which debts are checked
  const [checkedDebts, setCheckedDebts] = useState([]);

  const [termCoverage, setTermCoverage] = useState([]);
  const [wholeCoverage, setWholeCoverage] = useState([]);
  const [finalCoverage, setFinalCoverage] = useState([]);

  const [selectedTermIds, setSelectedTermIds] = useState([]);
  const [selectedWholeIds, setSelectedWholeIds] = useState([]);


  const handleCheckboxChangeterm = (termId) => {
    setSelectedTermIds((prevSelectedIds) =>
      prevSelectedIds.includes(termId)
        ? prevSelectedIds.filter((id) => id !== termId)
        : [...prevSelectedIds, termId]
    );
  };

  const handleCheckboxChangewhole = (wholeId) => {
    setSelectedWholeIds((prevSelectedIds) =>
      prevSelectedIds.includes(wholeId)
        ? prevSelectedIds.filter((id) => id !== wholeId)
        : [...prevSelectedIds, wholeId]
    );
  };


  // const handleCheckboxChangefinal = (finalId) => {
  //   setSelectedFinalIds((prevSelectedFinalIds) =>
  //     prevSelectedFinalIds.includes(finalId)
  //       ? prevSelectedFinalIds.filter((id) => id !== finalId)
  //       : [...prevSelectedFinalIds, finalId]
  //   );
  // };

  useEffect(() => {
    if (Array.isArray(termData)) {
      const coverage = termData.map((item) => item.termCoverage);
      setTermCoverage(coverage);
    } else {
      setTermCoverage([]);
    }
  }, [termData]);
 

  useEffect(() => {
    if (Array.isArray(wholeData)) {
      const coverage = wholeData.map((item) => item.wholeLifeCoverage);
      // console.log('Coverage:', coverage);
      setWholeCoverage(coverage);
    } else {
      setWholeCoverage([]);
    }
  }, [wholeData]);

  useEffect(() => {
    if (Array.isArray(finalData)) {
      const coverage = finalData.map((item) => item.finalExpenseCoverage);
      // console.log('Coverage:', coverage);
      setFinalCoverage(coverage);
    } else {
      setFinalCoverage([]);
    }
  }, [finalData]);

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await api.get(`/debt/getAllDebtDetails/${userId}`);
        setDebtData(response.data.debtData);
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };

    if (debtData === undefined && userId) {
      fetchDebtData();
    }
  }, []);

  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await api.get(
          `/coverage/getAllTermCoverageDetails/${userId}`
        );
        setTermData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };
    //   console.log("setTermData", termData);

    if (termData === undefined && userId) {
      fetchTermData();
    }
  }, [termData]);

  useEffect(() => {
    const fetchWholeData = async () => {
      try {
        const response = await api.get(
          `/coverage/getAllWholeLifeCoverageDetails/${userId}`
        );
        setWholeData(response.data.coverageData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (wholeData === undefined && userId) {
      fetchWholeData();
    }
  }, [wholeData]);

  // useEffect(() => {
  //   const fetchFinalData = async () => {
  //     try {
  //       const response = await api.get(
  //         `/coverage/getAllFinalExpenseCoverageDetails/${userId}`
  //       );
  //       setFinalData(response.data.coverageData);
  //     } catch (error) {
  //       console.error("Error fetching final coverage data:", error);
  //     }
  //   };
  //   //   console.log("setFinalData", finalData);
  //   if (finalData === undefined && userId) {
  //     fetchFinalData();
  //   }
  //   // if (userId) {
  //   //   fetchFinalData();
  //   // }
  // }, [finalData]);

  const handleToggle = () => {
    setIsShown(!isShown);
  };

  const handleToggleDebate = () => {
    setIsShownDebate(!isShownDebate);
  };

  const handleReviewClick = () => {
    setIsClicked(true);
    navigate(`/details/:id/reviews`);
  };

  const handleRoute = () => {
    navigate("/dashboard");
  };

  // const handleCreate = () => {
  //   navigate("/create");
  // };

  const handleDebtRoute = (debtId) => {
    navigate(`/details/${clientData.id}/debt/${userId}/${debtId}`);
  };

  // const handleFinalRoute = (finalId) => {
  //   console.log("Navigating to debt details with ID:", finalId);
  //   navigate(`/details/${clientData.id}/coverage/${userId}/${finalId}`);
  // };

  // const handleWholeRoute = (wholeId) => {
  //   navigate(`/details/${clientData.id}/coverage/${userId}/${wholeId}`);
  // };

  // const handleTermRoute = (termId) => {
  //   navigate(`/details/${clientData.id}/coverage/${userId}/${termId}`);
  // };

  const handleTermRoute = (termId) => {
    navigate(`/details/${clientData.id}/coverage/${userId}/${termId}/term`);
  };
  
  const handleWholeRoute = (wholeId) => {
    navigate(`/details/${clientData.id}/coverage/${userId}/${wholeId}/wholeLifeCoverage`);
  };
  
  // const handleFinalRoute = () => {
  //   navigate(`/details/${clientData.id}/coverage/${userId}/final`);
  // };
  

  const handleAddClick = () => {
    navigate(`/details/${clientData.id}/debt`);
  };

  const handleAddClickCoverage = () => {
    navigate(`/details/${clientData?.id}/coverage`);
  };

  const handleCheckboxChange = (loanId) => {
    setCheckedDebts((prevCheckedDebts) =>
      prevCheckedDebts.includes(loanId)
        ? prevCheckedDebts.filter((id) => id !== loanId)
        : [...prevCheckedDebts, loanId]
    );
  };

  const deleteDebt = async () => {
    try {
      if (checkedDebts.length === 0) {
        return;
      }
  
      await Promise.all(
        checkedDebts.map(async (debtId) => {
          await api.delete(`/debt/deleteDebt/${debtId}`);
        })
      );
  
      const response = await api.get(`/debt/getAllDebtDetails/${userId}`);
      setDebtData(response.data.debtData); // Update the UI with the latest data
  
      setCheckedDebts([]);
  
      notifySuccess("Debt deleted successfully!");
    } catch (error) {
      console.error("Error deleting debt:", error);
    }
  };

  const deleteTerm = async () => {
    try {
      if (selectedTermIds.length === 0) {
        return;
      }
  
      await Promise.all(
        selectedTermIds.map(async (termId) => {
          await api.delete(`/coverage/deleteTermCoverage/${termId}`);
        })
      );
  
      const response = await api.get(
        `/coverage/getAllTermCoverageDetails/${userId}`
      );
      setTermData(response.data.termData); // Update the UI with the latest data
  
      setSelectedTermIds([]);
  
      notifySuccess("Term Coverage deleted successfully!");
    } catch (error) {
      console.error("Error deleting term coverage:", error);
    }
  };

  const deleteWhole = async () => {
    try {
      if (selectedWholeIds.length === 0) {
        return;
      }
  
      await Promise.all(
        selectedWholeIds.map(async (wholeId) => {
          await api.delete(`/coverage/deleteWholeLifeCoverage/${wholeId}`);
        })
      );
  
      const response = await api.get(
        `/coverage/getAllWholeLifeCoverageDetails/${userId}`
      );
      setWholeData(response.data.wholeData); // Update the UI with the latest data
  
      setSelectedWholeIds([]);
  
      notifySuccess("Whole Life Coverage deleted successfully!");
    } catch (error) {
      console.error("Error deleting whole life coverage:", error);
    }
  };

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: "drawer-paper" }}
      sx={{
        "& .MuiDrawer-paper": {
          position: "relative",
          height: "100vh",
          background: "#DDDDDD",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <Box className="left-side">
          <Box className="logo-section">
            <img
              src="https://zibtek.nyc3.cdn.digitaloceanspaces.com/Mazzzing/insurality.png"
              alt="Logo"
              style={{ height: 40 }}
            />
          </Box>
          {/* <Box sx={{ margin: "0 45px", height: "35px" }}>
            {" "}
            <TextField
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#B0B0B0" }} />{" "}
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFF",
                  borderRadius: 1,
                  height: "35px",
                },
              }}
            />
          </Box> */}
          <List sx={{ padding: "20px" }}>
            <div className="row">
              <div className="leftSection" onClick={handleRoute}>
                <span className="icon">◀</span>
                <span className="text">
                  {clientData?.clientInfo?.personal?.firstName
                    ? `${clientData.clientInfo.personal.firstName} ${clientData.clientInfo.personal.lastName}`
                    : `${userDetails?.firstName} ${userDetails?.lastName}`}
                </span>
              </div>
              {/* <span className="circles plus" onClick={handleCreate}></span> */}
            </div>

            <div className="top">
              <div className="row" onClick={handleToggle}>
                <div className="leftSection">
                  <span className="icon">{isShown ? "▼" : "▶"}</span>
                  <span className="text">COVERAGE</span>
                </div>
                <span onClick={handleAddClickCoverage}>
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent", // Prevent background change on hover
                      },
                    }}
                  >
                    <AddIcon className="plus" />
                  </IconButton>
                </span>
              </div>

              {isShown && (
                <>
                  <h4 className="textAlign">Term</h4>

                  <List component="div" disablePadding>
                    {termData && termData.length > 0 ? (
                      termData.map((coverage, index) => {
                        const termId = coverage._id;

                        return (
                          <ListItem
                            key={index}
                            sx={{
                              pl: 2,
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent background change on hover
                              },
                            }}
                            onClick={() => handleTermRoute(termId)}
                            // onClick={() => handleDebtRoute(termId)}
                            // onClick={() => navigate(`/details/${localClient.id}/coverage/${userId}`)}
                            button // Makes the entire ListItem clickable
                          >
                            <input
                              type="checkbox"
                              className="leftCheckbox"
                              checked={selectedTermIds.includes(termId)}
                              onChange={() => handleCheckboxChangeterm(termId)}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent routing when clicking the checkbox
                              }}
                            />
                            <div className="new-row">
                              <div className="text-cov">
                                <span className="key-style">
                                  {coverage.termCoverage.carrier}
                                </span>
                              </div>
                              <div className="iconsize">
                                <span style={{ color: "gray" }}>
                                  $ {coverage.termCoverage.deathBenefit}
                                </span>
                              </div>
                            </div>
                            <ListItemText />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          No Terms Coverage found.
                        </Typography>
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          height: 25,
                          padding: "3px 5px",
                          backgroundColor: "#0A406D",
                          fontSize: "10px",
                          color: "#C7C8CA",
                          "&:hover": {
                            backgroundColor: "#0A406D", // Disables the hover effect
                          },
                        }}
                        onClick={deleteTerm}
                        disabled={!selectedTermIds || selectedTermIds.length === 0}
                      >
                        DELETE
                      </Button>
                    </ListItem>
                  </List>
                  <h4 className="textAlign">Permanent</h4>

                  <List component="div" disablePadding>
                    {wholeData && wholeData.length > 0 ? (
                      wholeData.map((coverage, index) => {
                        const wholeId = coverage._id;

                        return (
                          <ListItem
                            key={index}
                            sx={{
                              pl: 2,
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent background change on hover
                              },
                            }}
                            onClick={() => handleWholeRoute(wholeId)}
                            button
                          >
                            <input
                              type="checkbox"
                              className="leftCheckbox"
                              checked={selectedWholeIds.includes(wholeId)}
                              onChange={() =>
                                handleCheckboxChangewhole(wholeId)
                              }
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent routing when clicking the checkbox
                              }}
                            />
                            <div className="new-row">
                              <div className="text-cov">
                                <span className="key-style">
                                  {coverage.wholeLifeCoverage.carrier}
                                </span>
                              </div>
                              <div className="iconsize">
                                <span style={{ color: "gray" }}>
                                  $ {coverage.wholeLifeCoverage.year1}
                                </span>
                              </div>
                            </div>
                            <ListItemText />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          No Permanent Coverage found.
                        </Typography>
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          height: 25,
                          padding: "3px 5px",
                          backgroundColor: "#0A406D",
                          fontSize: "10px",
                          color: "#C7C8CA",
                          "&:hover": {
                            backgroundColor: "#0A406D", // Disables the hover effect
                          },
                        }}
                        onClick={deleteWhole}
                        disabled={!selectedWholeIds || selectedWholeIds.length === 0}
                      >
                        DELETE
                      </Button>
                    </ListItem>
                  </List>

                  {/* <h4 className="textAlign">Final Expense</h4>

                  <List component="div" disablePadding>
                    {finalData && finalData.length > 0 ? (
                      finalData.map((coverage, index) => {
                        const finalId = coverage._id;

                        return (
                          <ListItem
                            key={index}
                            onClick={() => handleFinalRoute(finalId)}
                            sx={{
                              pl: 2,
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent background change on hover
                              },
                            }}
                            button
                          >
                            <input
                              type="checkbox"
                              className="leftCheckbox"
                              checked={selectedFinalIds.includes(finalId)}
                              onChange={() =>
                                handleCheckboxChangefinal(finalId)
                              }
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent routing when clicking the checkbox
                              }}
                            />
                            <div className="new-row">
                              <div className="text-cov">
                                <span className="key-style">
                                  {coverage.finalExpenseCoverage.carrier}
                                </span>
                              </div>
                              <div className="iconsize">
                                <span style={{ color: "gray" }}>
                                  $ {coverage.finalExpenseCoverage.deathBenefit}
                                </span>
                              </div>
                            </div>
                            <ListItemText />
                          </ListItem>
                        );
                      })
                    ) : (
                      <ListItem>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          No Final Expenses Coverage found.
                        </Typography>
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          height: 25,
                          padding: "3px 5px",
                          backgroundColor: "#0A406D",
                          fontSize: "10px",
                          color: "#C7C8CA",
                          "&:hover": {
                            backgroundColor: "#0A406D", // Disables the hover effect
                          },
                        }}
                        onClick={deleteFinal}
                      >
                        DELETE
                      </Button>
                    </ListItem>
                  </List> */}
                </>
              )}
            </div>
            <Divider sx={{ borderColor: "#fff" }} />
            <div>
              <div className="row" onClick={handleToggleDebate}>
                <div className="leftSection">
                  <span className="icon">{isShownDebate ? "▼" : "▶"}</span>
                  <span className="text">DEBT</span>
                </div>
                <span onClick={handleAddClick}>
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent", // Prevent background change on hover
                      },
                    }}
                  >
                    <AddIcon className="plus" />
                  </IconButton>
                </span>
              </div>
              {isShownDebate && (
                <List component="div" disablePadding>
                  {debtData && debtData.length > 0 ? (
                    debtData.map((loan, index) => {
                      const loanId = loan._id;

                      return (
                        <ListItem
                          key={index}
                          sx={{
                            pl: 2,
                            "&:hover": {
                              backgroundColor: "transparent", // Prevent background change on hover
                            },
                          }}
                          onClick={() => handleDebtRoute(loanId)} // Pass the loanId to handleDebtRoute
                          button // Makes the entire ListItem clickable
                        >
                          <input
                            type="checkbox"
                            className="leftCheckbox"
                            checked={checkedDebts.includes(loanId)}
                            onChange={() => handleCheckboxChange(loanId)}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent routing when clicking the checkbox
                            }}
                          />
                          <div className="new-row">
                            <div className="text-cov">
                              <span className="key-style">{loan.type}</span>
                            </div>
                            <div className="iconsize">
                              <span style={{ color: "gray" }}>
                                $ {loan.loanAmount}
                              </span>
                            </div>
                          </div>
                          <ListItemText />
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem>
                      <Typography variant="body1" sx={{ pl: 2 }}>
                        No Debts found.
                      </Typography>
                    </ListItem>
                  )}
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      height: "20",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        height: 25,
                        padding: "3px 5px",
                        backgroundColor: "#0A406D",
                        fontSize: "10px",
                        color: "#C7C8CA",
                        "&:hover": {
                          backgroundColor: "#0A406D", // Disables the hover effect
                        },
                      }}
                      onClick={() => deleteDebt()}
                      disabled={!checkedDebts || checkedDebts.length === 0} // Disable if no checkbox is checked
                    >
                      DELETE
                    </Button>
                  </ListItem>
                </List>
              )}

            </div>
          </List>
        </Box>

        {/* <Box className="bottom-section">
          <Typography variant="body1" sx={{letterSpacing: 2,fontWeight:'bold'}} onClick={handleReviewClick}>
            REVIEWS
          </Typography>
        </Box> */}
        {/* <Box className="bottom-section">
          <Typography
            variant="body1"
            sx={{
              letterSpacing: 2,
              fontWeight: "bold",
              color: isClicked ? "#fff" : "initial",
            }}
            onClick={handleReviewClick} // This triggers on a single click
          >
            REVIEWS
          </Typography>
        </Box> */}
      </Box>
      <ToastContainer />
    </Drawer>
  );
};

export default LeftSidenav;
