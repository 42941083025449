

import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../../api/tokenCode";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { notifySuccess,notifyError } from "../../constant/toast";

const theme = createTheme();

function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();


  const validate = () => {
    const errors = {};

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    // if (!confirmPassword) {
    //   errors.confirmPassword = "Confirm Password is required";
    // } else if (password !== confirmPassword) {
    //   errors.confirmPassword = "Passwords do not match";
    // }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
  
    setLoading(true);
  
    setTimeout(async () => {
      try {
        const response = await api.post("/user/reset-password", {
          password: password,
        });
  
        if (response.status === 200) {
          notifySuccess("Password Updated successfully!");
          setSuccess(true);
          setTimeout(() => {
            navigate("/");
          }, 2000); // Navigate to login page after 3 seconds
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        console.error("Error:", error);
        notifyError("Error in submitting ,Please try again.");
        setErrors({ ...errors, form: "Request failed. Please try again." });
      } finally {
        setLoading(false);
      }
    }, 2000);
  };
  

  return (
    <Box
      className="reset-password-page"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#101846',
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: 'white',
              padding: 4, // Add padding for spacing
              borderRadius: 3, // Optional: Add border radius for rounded corners
              boxShadow: 3, // Optional: Add box shadow for a subtle elevation effect
            }}
          >
            <Box className="logo-section">
              <img
                src="https://zibtek.nyc3.cdn.digitaloceanspaces.com/Mazzzing/insurality.png"
                alt="Logo"
                style={{ height: 40 }}
              />
            </Box>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            {/* {success ? (
              <Typography variant="body1" color="success.main">
                Your password has been reset successfully. You can now <a href="/">login</a> with your new password.
              </Typography>
            ) : ( */}
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword}
                />
                {errors.form && (
                  <Typography color="error" variant="body2">
                    {errors.form}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Reset Password"}
                </Button>
              </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <ToastContainer />
    </Box>
  );
}

export default ResetPasswordPage;
