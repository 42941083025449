

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Review.css";
import api from "../../api/tokenCode";

const Reviews = () => {
  const clientData = JSON.parse(localStorage.getItem('client'));
  const clientId = clientData?.user_id || '';
  console.log(clientId,"clientId");

  const today = new Date();
  const formattedDate = `${
    today.getMonth() + 1
  }/${today.getDate()}/${today.getFullYear()}`;

  const initialFormState = {
    questions: [
      "Any major life changes since last review?",
      "Any updates or changes to the current coverage?",
      "Has anything changed in your health?",
      "Are all family members adequately covered?",
      "Is it time to consider converting your term policy to permanent?",
      "Do you have an old 401K from a previous job?",
      "Are you happy with how your retirement accounts have been doing?",
      "Do you have any debts you’re trying to pay off?",
      "Are there any friends or neighbors who need my services?",
    ].map((question) => ({ question, answer: "", checked: false })),
    notes: "",
    id: 1,
    clientId: clientId
  };

  const [forms, setForms] = useState([initialFormState]);

  useEffect(() => {
    // Assuming you want to load the review data from an API or localStorage
    const loadReviewData = async () => {
      try {
        const reviewData = JSON.parse(localStorage.getItem('reviewData')) || null;
        if (reviewData) {
          const loadedForms = reviewData.map((data, index) => ({
            id: index + 1,
            clientId: data.clientId,
            notes: data.lastTextField,
            questions: initialFormState.questions.map((question, questionIndex) => {
              return {
                question,
                answer: data[`${question.replace(/[^\w\s]/g, "").replace(/\s+/g, "").toLowerCase()}` + "Textfield"] || '',
                checked: data[`${question.replace(/[^\w\s]/g, "").replace(/\s+/g, "").toLowerCase()}`] || false
              };
            })
          }));

          setForms(loadedForms);
        }
      } catch (error) {
        console.error("Error loading review data:", error);
      }
    };

    loadReviewData();
  }, [clientId]);

  const addNewForm = () => {
    const newFormId = forms.length + 1;
    setForms([
      ...forms,
      {
        ...initialFormState,
        id: newFormId,
      },
    ]);
  };

  const handleInputChange = (formIndex, questionIndex, type, value) => {
    setForms((prevForms) => {
      const updatedForms = prevForms.map((form, index) => {
        if (index === formIndex) {
          const updatedForm = { ...form };

          if (type === "notes") {
            updatedForm.notes = value;
          } else {
            updatedForm.questions = updatedForm.questions.map((q, qIndex) => {
              if (qIndex === questionIndex) {
                return { ...q, [type]: value };
              }
              return q;
            });
          }

          return updatedForm;
        }
        return form;
      });
      return updatedForms;
    });
  };

  const handleSubmit = async () => {
    const formData = forms.map((form) => {
      const transformedAnswers = {};

      form.questions.forEach((q) => {
        const baseKey = q.question
          .replace(/[^\w\s]/g, "") 
          .replace(/\s+/g, " ") 
          .trim()
          .toLowerCase()
          .split(" ")
          .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join("");

        transformedAnswers[baseKey] = q.checked;
        transformedAnswers[`${baseKey}Textfield`] = q.answer;
      });

      return {
        ...transformedAnswers,
        lastTextField: form.notes, 
        clientId: form.clientId, 
      };
    });

    try {
      const response = await api.post("/review/createReview", {
        review: formData,
      });

      if (!response.ok) throw new Error("Network response was not ok");

      setForms([initialFormState]); // reset after submission
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: "auto" }}>
        <section>
          <div className="main-container">
            <h1 className="review-name">REVIEWS</h1>
          </div>
          {forms.map((form, formIndex) => (
            <div className="main-new-container" key={form.id}>
              <div className="review-form-container">
                <div className="container">
                  <p className="text-with-underline">
                    {formIndex === 0 ? (
                      <span className="circle plus" onClick={addNewForm}></span>
                    ) : (
                      <span className="icons"> </span>
                    )}
                    <span className="date">{formattedDate}</span>
                    <span className="review-items">REVIEW ITEMS</span>
                  </p>
                </div>
                <div style={{marginTop:'10px'}}>
                  {form.questions.map((q, index) => (
                    <div className="form-item" key={index}>
                      <p className="line-with-text">
                        <span className="left-text">{q.question}</span>
                        <span className="right-inputs">
                          <input
                            type="checkbox"
                            className="customs-checkbox"
                            id={`question-${form.id}-${index + 1}`}
                            name={`question-${form.id}-${index + 1}`}
                            checked={q.checked}
                            onChange={(e) =>
                              handleInputChange(
                                formIndex,
                                index,
                                "checked",
                                e.target.checked
                              )
                            }
                          />
                          <input
                            type="text"
                            onInput={(e) => {
                              let inputValue = e.target.value.trimStart();
                              inputValue = inputValue.replace(
                                /[^A-Za-z0-9\s.,#\-&@()\/\\'";:!?%$*+=<>~`|]/g,
                                ""
                              );
                              if (inputValue) {
                                e.target.value =
                                  inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                              } else {
                                e.target.value = "";
                              }
                            }}
                            id={`details-${form.id}-${index + 1}`}
                            name={`details-${form.id}-${index + 1}`}
                            placeholder="Notes"
                            value={q.answer}
                            onChange={(e) =>
                              handleInputChange(
                                formIndex,
                                index,
                                "answer",
                                e.target.value
                              )
                            }
                          />
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="full-width-container">
                <textarea
                  type="text"
                  onInput={(e) => {
                    let inputValue = e.target.value.trimStart();
                    inputValue = inputValue.replace(
                      /[^A-Za-z0-9\s.,#\-&@()\/\\'";:!?%$*+=<>~`|]/g,
                      ""
                    );
                    if (inputValue) {
                      e.target.value =
                        inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                    } else {
                      e.target.value = "";
                    }
                  }}
                  className="full-width-input"
                  placeholder="Notes"
                  value={form.notes}
                  onChange={(e) =>
                    handleInputChange(formIndex, null, "notes", e.target.value)
                  }
                />
              </div>
            </div>
          ))}
          <div>
            <button type="submit" className="btn" onClick={handleSubmit}>
              SAVE
            </button>
          </div>
        </section>
      </Box>
    </Box>
  );
};

export default Reviews;
