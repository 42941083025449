import React, { useState } from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Coverage.css";
import { format } from "date-fns";
import api from "../../api/tokenCode";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess ,notifyError} from "../../constant/toast";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"; // Import MUI DatePicker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Reusable InputField Component
const InputField = ({
  name,
  placeholder,
  className,
  field,
  error,
  type = "text",
  maxLength
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="input-field-container">
      
        <input
          type={type}
          maxLength={maxLength}
          onInput={(e) => {
            if (type === "text") {
              // let inputValue = e.target.value;
              // inputValue = inputValue.length > 1 ? inputValue.trimStart() : inputValue; // Trim leading spaces if more than one character
              // e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1); // Capitalize the first letter
            } else if (type === "number") {
              e.target.value = e.target.value.replace(/[eE]/g, ""); // Prevent 'e' and 'E'
            }
          }}
          className={className}
          placeholder={placeholder}
          {...field}
        />
      {error && <p className="error-message">{error.message}</p>}
    </div>
  );
};

// Updated FormSection to pass 'type' dynamically
const FormSection = ({ form, onSubmit, fields, title }) => (
  <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="containers">
        <p className="text-with-underline">{title}</p>
      </div>
      <hr className="hr-tag" />
      <div className="input-container">
        {fields.map(({ name, placeholder, className, rules, type }) => (
          <Controller
            key={name}
            name={name}
            control={form.control}
            rules={rules}
            render={({ field }) => (
              <InputField
                name={name}
                placeholder={placeholder}
                className={className}
                field={field}
                type={type}
                error={form.formState.errors[name]}
                maxLength={name === "wholeLifeStartDate" || name === "termStartDate" ? 4 : undefined}
              />
            )}
          />
        ))}
        <div className="button-div">
        <button type="submit" className="submit-button">
            ADD
          </button>
        </div>
      </div>
    </form>
  );

const Coverage = () => {
  const termForm = useForm();
  const wholeLifeForm = useForm();
  // const finalExpenseForm = useForm();
  const [savedCoverage, setSavedCoverage] = useState(null);

  const [termDetails, setTermDetails] = useState([]);

  const [wholeLifeDetails, setWholeLifeDetails] = useState([]);

  // const [finalExpenseDetails, setFinalExpenseDetails] = useState([]);
  const navigate = useNavigate();
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientId = clientData?.user_id || "";

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const handleSubmitTermCoverage = async (data) => {
    const processedData = {
      termCoverage: {
        carrier: data.termCarrier || "",
        deathBenefit: data.termDeathBenefit || "",
        startDate: data.termStartDate || "",
        length: data.termLengthYears || "",
      },
      clientId,
      coverageType: "term",
    };

    const updatedLoanDetails = [...termDetails, processedData];

    setTermDetails(updatedLoanDetails);

    try {
      const response = await api.post("/coverage/createTermCoverage", {
        processedData: updatedLoanDetails,
      });
      console.log(response);
      setTimeout(() => {
        notifySuccess("Term Created successfully!"); // Dismiss the toast manually after 3 seconds
      }, 500);
      
      termForm?.reset({
        termCarrier: "",
        termDeathBenefit: "",
        termStartDate: "",
        termLengthYears: "",
      });
      // setSavedCoverage(response.data);
      setSavedCoverage(null);
      // Set the savedCoverage state

     
      //  setTimeout(() => {
      //    navigate(`/details/${clientData.id}`);
      //  }, 3000);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const handleSubmitWholeLifeCoverage = async (data) => {
    const processedData = {
      wholeLifeCoverage: {
        carrier: data.wholeLifeCarrier || "",
        year1: data.wholeLifeYear1 || "",
        year5: data.wholeLifeYear5 || "",
        year10: data.wholeLifeYear10 || "",
        year20: data.wholeLifeYear20 || "",
        year40: data.wholeLifeYear40 || "",
        startDate: data.wholeLifeStartDate || "",
      },
      clientId,
      coverageType: "wholeLifeCoverage",
    };
    const updatedLoanDetails = [...wholeLifeDetails, processedData];

    // setWholeLifeDetails(updatedLoanDetails);
    setWholeLifeDetails([]);
    try {
      const response = await api.post("/coverage/createWholeLifeCoverage", {
        processedData: updatedLoanDetails,
      });
      console.log(response);
      setTimeout(() => {
        notifySuccess("Permanent Created successfully!"); // Dismiss the toast manually after 3 seconds
      }, 500);

      // setSavedCoverage(response.data); // Set the savedCoverage state
      wholeLifeForm.reset({
        wholeLifeCarrier: "",
        wholeLifeYear1: "",
        wholeLifeYear5: "",
        wholeLifeYear10: "",
        wholeLifeYear20: "",
        wholeLifeYear40: "",
        wholeLifeStartDate: "",
      });

       setSavedCoverage(null);

     

      //  setTimeout(() => {
      //    navigate(`/details/${clientData.id}`);
      //  }, 3000);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };



  const termFields = [
    {
      name: "termCarrier",
      placeholder: "Carrier",
      className: "input-field-sub-1",
      rules: { required: "* Carrier is required" },
    },
    {
      name: "termDeathBenefit",
      placeholder: "Death Benefit",
      type: "number",
      className: "input-field-sub-2",
      rules: { required: "* Death Benefit is required" },
    },
    {
      name: "termStartDate",
      placeholder: "Start Year",
      className: "input-field-sub-2",
      maxLength: { value: 4, message: "* Start Year must be 4 digits" }
    },
    {
      name: "termLengthYears",
      placeholder: "Length (years)",
      className: "input-field-sub-2",
      rules: { required: "* Length (years) is required" },
    },
  ];

  const wholeLifeFields = [
    {
      name: "wholeLifeCarrier",
      placeholder: "Carrier",
      className: "input-field-sub-1",
      rules: { required: "* Carrier is required" },
    },
    {
      name: "wholeLifeYear1",
      placeholder: "Year 1",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 1 is required" },
    },
    {
      name: "wholeLifeYear5",
      placeholder: "Year 5",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 5 is required" },
    },
    {
      name: "wholeLifeYear10",
      placeholder: "Year 10",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 10 is required" },
    },
    {
      name: "wholeLifeYear20",
      placeholder: "Year 20",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 20 is required" },
    },
    {
      name: "wholeLifeYear40",
      placeholder: "Year 100",
      type: "number",
      className: "input-field-5",
      rules: { required: "* Year 40 is required" },
    },
    {
      name: "wholeLifeStartDate",
      placeholder: "Start Year",
      className: "input-field-sub-2",
      maxLength: { value: 4, message: "* Start Year must be 4 digits" }
    },
  ];
  
  // const finalExpenseFields = [
  //   {
  //     name: "finalExpenseCarrier",
  //     placeholder: "Carrier",
  //     className: "input-field-sub-1",
  //     rules: { required: "* Carrier is required" },
  //   },
  //   {
  //     name: "finalExpenseDeathBenefit",
  //     placeholder: "Death Benefit",
  //     type: "number",
  //     className: "input-field-sub-2",
  //     rules: { required: "* Death Benefit is required" },
  //   },
  //   {
  //     name: "finalExpenseStartDate",
  //     placeholder: "Start Date",
  //     type: "date",
  //     className: "input-field-sub-2",
  //     rules: { required: "* Start Date is required" },
  //   },
  // ];

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav />
      <Box
        component="main"
        className="coveragePadding"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          overflowY: "auto",
        }}
      >
        <div>
          <h1 className="debt-name">COVERAGE</h1>
          <button
            type="button"
            className="back-button"
            onClick={handleRowClick}
          >
            BACK
          </button>
        </div>
        <FormSection
          form={termForm}
          onSubmit={handleSubmitTermCoverage}
          fields={termFields}
          title="TERM"
        />
        <FormSection
          form={wholeLifeForm}
          onSubmit={handleSubmitWholeLifeCoverage}
          fields={wholeLifeFields}
          title="PERMANENT"
        />
        {/* <FormSection
          form={finalExpenseForm}
          onSubmit={handleSubmitFinalExpenseCoverage}
          fields={finalExpenseFields}
          title="FINAL EXPENSE"
        /> */}
      </Box>
    </Box>
  );
};

export default Coverage;
