import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed
// import DatePicker from "react-datepicker";
import "./FormDesign.css";
// import { format } from "date-fns";
// import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';

const ClientInfo = ({ onDataChange, initialData }) => {
  const [formData, setFormData] = useState({
    personal: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    spouse: {
      firstName: "",
      lastName: "",
      birthDay: "",
    },
    location: {
      address: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const [open, setOpen] = useState(false);
  const [touched, setTouched] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      personal: {
        ...prevData.personal,
        birthDay: newDate ? dayjs(newDate).format('MM/DD/YYYY') : null, // Save date in MM/DD/YYYY format
      },
    }));
    setOpen(false); // Close the date picker after selecting a date
  };



  const handleInputChange = (e) => {
    let value = e.target.value;

    // Extract parts of the date
    const [month, day, year] = value.split("/");

    // Validate and correct month
    if (month && parseInt(month, 10) > 12) {
      value = `12/${day || ""}/${year || ""}`;
    }

    // Update the state with the corrected value
    setInputValue(value);
  };

  // Update formData when initialData changes
  useEffect(() => {
    if (initialData) {
      setFormData({
        personal: {
          firstName: initialData.personal?.firstName || "",
          lastName: initialData.personal?.lastName || "",
          birthDay: initialData.personal?.birthDay || "",
        },
        spouse: {
          firstName: initialData.spouse?.firstName || "",
          lastName: initialData.spouse?.lastName || "",
          birthDay: initialData.spouse?.birthDay || "",
        },
        location: {
          address: initialData.location?.address || "",
          city: initialData.location?.city || "",
          state: initialData.location?.state || "",
          zip: initialData.location?.zip || "",
        },
      });
    }
  }, [initialData]);

  // Call onDataChange when formData changes
  useEffect(() => {
    onDataChange(formData);
  }, [formData, onDataChange]);

  // Handle changes in input fields
  const handleChange = (section, field, value) => {
    setFormData((prevData) => {
      const updatedValue = value.length > 1 
        ? value.trimStart().charAt(0).toUpperCase() + value.trimStart().slice(1)
        : value;
  
      const updatedData = {
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: updatedValue,
        },
      };
  
      return updatedData;
    });
  };
  
  

  return (
    <>
      <Header name="CLIENT INFO" className="headerSpace" />
      <Box className="mt-1" sx={{ marginBottom: 6, padding: 2 }}>
        {/* Personal Information */}
        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="newClass"
            >
              <Typography variant="body1" className="list-1">
                Personal
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <input
                type="text"

                placeholder="First Name"
                value={formData.personal.firstName}
                onChange={(e) => handleChange("personal", "firstName", e.target.value)}
                onBlur={(e) => handleChange("personal", "firstName", e.target.value.trim())} // Trim extra spaces only on blur
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Last Name"
                value={formData.personal.lastName}
                onChange={(e) => handleChange("personal", "lastName", e.target.value)}
                onBlur={(e) => handleChange("personal", "lastName", e.target.value.trim())}
              />
            </div>

            <div className="grid-item">

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                onError={(error) => {
                  if (error) {
                    setError("Invalid date format"); // Set the error message when there's a date error
                  } else {
                    setError(null); // Clear the error when the date is valid
                  }
                }}
              >
                <DatePicker
                  views={['year', 'month', 'day']}
                  value={
                    formData.personal.birthDay
                      ? dayjs(formData.personal.birthDay, 'MM/DD/YYYY', true)
                      : null
                  }
                  onChange={(newDate) => {
                    setTouched(true); // Mark the field as touched
                    if (!newDate) {
                      setError("Date is required"); // Error for empty date
                      handleDateChange(""); // Update state with an empty value
                    } else if (dayjs(newDate).isValid()) {
                      handleDateChange(newDate.format('MM/DD/YYYY')); // Update the date in MM/DD/YYYY format
                      setError(null); // Clear any error
                    } else {
                      setError("Invalid date format"); // Set error for invalid date
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={(e) => {
                        setTouched(true);
                        const inputValue = e.target.value.trim();
                        if (!inputValue) {
                          setError("Date is required"); // Show error if empty
                          handleDateChange(""); // Update state with an empty value
                        } else if (!dayjs(inputValue, 'MM/DD/YYYY', true).isValid()) {
                          setError("Invalid date format"); // Show error for invalid input
                          handleDateChange(""); // Reset to empty if the input is invalid
                        } else {
                          handleDateChange(
                            dayjs(inputValue, 'MM/DD/YYYY', true).format('MM/DD/YYYY')
                          ); // Update state with valid input
                          setError(null); // Clear error
                        }
                      }}
                      error={touched && !!error} // Show error only when touched
                      helperText={touched && error ? error : ""} // Show helper text for error
                      sx={{
                        '& .MuiInputBase-root': {
                          border: '1px solid #BFC0C2',
                          backgroundColor: '#f9f9f9',
                          borderRadius: '0',
                          cursor: 'pointer',
                          width: '150px',
                        },
                        '& input': {
                          fontSize: '15px',
                          textTransform: 'lowercase',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#BFC0C2',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#BFC0C2',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#BFC0C2',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '18px',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </Grid>

        {/* Location Information */}
        <Grid
          container
          spacing={2}
          className="gridHeight"
          alignItems="center"
          sx={{ mt: 1, flexWrap: "nowrap" }}
        >
          <Grid xs={12} sm={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" className="list-1 locResp">
                Location
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item address-input">
              <input
                type="text"
                className="address-input"
                onInput={(e) => {
                  // Trim leading spaces
                  let inputValue = e.target.value.trimStart();
                  inputValue = inputValue.replace(
                    /[^A-Za-z0-9\s.,#\-&@()\/\\'";:!?%$*+=<>~`|]/g,
                    ""
                  );
                  if (inputValue) {
                    e.target.value =
                      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                  } else {
                    e.target.value = "";
                  }
                }}
                placeholder="Address"
                value={formData.location.address}
                onChange={(e) =>
                  handleChange("location", "address", e.target.value)
                }
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="City"
                value={formData.location.city}
                onChange={(e) => handleChange("location", "city", e.target.value)}
                onBlur={(e) => handleChange("location", "city", e.target.value.trim())}
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="State"
                value={formData.location.state}
                onInput={(e) => {
                  // Trim leading spaces only
                  let inputValue = e.target.value.trimStart();

                  // Capitalize the first letter if the input starts with a character
                  e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                }}
                onChange={(e) => handleChange("location", "state", e.target.value)}
                onBlur={(e) => handleChange("location", "state", e.target.value.trim())}
              />
            </div>

            <div className="grid-item">
              <input
                type="text"
                placeholder="Zip"
                maxLength={6}
                value={formData.location.zip}
                onChange={(e) => handleChange("location", "zip", e.target.value)}
                onBlur={(e) => handleChange("location", "zip", e.target.value.trim())}
              />
            </div>
          </div>
        </Grid>
      </Box >
    </>
  );
};

export default ClientInfo;
