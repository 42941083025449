import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import FamilyData from "./FamilyData";
import AssetsIncome from "./AssetIncome";
import FutureIncome from "./FutureIncome";
import FuturePlanningForm from "./FuturePlan";
import ClientInfo from "./ClientInfo";
import "./FormDesign.css";
import api from "../../api/tokenCode";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, notifyError } from '../../constant/toast';
import { useNavigate } from "react-router-dom";

const FormInfo = () => {                                                                                                                                                                                                                                                            
  const [clientInfo, setClientInfo] = useState({});
  const [assetsIncome, setAssetsIncome] = useState({});
  const [futureIncome, setFutureIncome] = useState({});
  const [futurePlanning, setFuturePlanning] = useState({});
  const [assetsData, setAssetsData] = useState({});
  const [errors, setErrors] = useState({
    clientInfo: {},
    assetsIncome: {},
    futureIncome: {},
    futurePlanning: {},
    assetsData: {},
  });
  const navigate = useNavigate();
  const clientData = JSON.parse(localStorage.getItem("client"));

  const validateForm = () => {
    // Validate all forms and return true if there are no errors
    const hasErrors = Object.keys(errors).some((key) => Object.keys(errors[key]).length > 0);
    return !hasErrors;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      notifyError('Please fix the errors before submitting.');
      return;
    }

    const payload = {
      clientInfo,
      assetsIncome,
      futureIncome,
      futurePlanning,
      assetsData,
    };

    try {
      const response = await api.post('/form/createForm', payload);
      notifySuccess('Form created Succesfully');
      console.log(response);
      
      
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);

    } catch (error) {
      console.error('Error:', error.response.data.message);
      notifyError(` ${error.response.data.message}`);

    }
  };

  const handleValidation = (formName, validationErrors) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [formName]: validationErrors,
    }));
  };

  const handleRowClick = () => {
    const routePath = clientData?.id ? `/details/${clientData.id}` : '/dashboard';
    navigate(routePath);
  };  

  return (
    <Box className="familyDataContainer" sx={{ width:'fit-content', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
      <Typography variant="h4" sx={{textAlign:'center',color:'#24527B',letterSpacing:'5px',fontFamily:'fangsong'}}>
        INTAKE FORM
      </Typography>

      <button type="submit" className="back-buttonintake" onClick={handleRowClick}>Back</button>


      <Box sx={{ mt: 3 }}>
        <ClientInfo onDataChange={setClientInfo} onValidate={(errors) => handleValidation('clientInfo', errors)} />
        <FamilyData onDataChange={setAssetsIncome} onValidate={(errors) => handleValidation('assetsIncome', errors)} />
        <AssetsIncome onDataChange={setAssetsData} onValidate={(errors) => handleValidation('assetsData', errors)} />
        <FutureIncome onDataChange={setFutureIncome} onValidate={(errors) => handleValidation('futureIncome', errors)} />
        <FuturePlanningForm onDataChange={setFuturePlanning} onValidate={(errors) => handleValidation('futurePlanning', errors)} />
      </Box>

      <Box sx={{ textAlign: "right", mt: 3 }}>
        <Button sx={{ marginRight: 2 }} onClick={() =>{navigate(`/details/${clientData.id}`)}}>
        Cancel</Button>
        <Button
          sx={{ marginRight: 2, backgroundColor: "#24527B", color: "white", '&:hover': { backgroundColor: '#24527B' } }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
     <ToastContainer />
    </Box>
  );
};

export default FormInfo;
