// src/api.js
import axios from 'axios';
import { BASE_URL } from '../constant/url';

const api = axios.create({
  baseURL: `${BASE_URL}`,
});

const Extractedtoken = window.location.href.split("token=")[1];
console.log("Extracted Token:", Extractedtoken);

// Retrieve the access token from localStorage
const accessToken = Extractedtoken || localStorage.getItem('access_token');

export const setAuthToken = (token) => {
  if (token) {
    console.log("Access Token:", token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// If you want to set the token automatically upon initialization:
if (accessToken) {
  setAuthToken(accessToken);
}

export default api;
